<template>
  <div id="articleStatic">
    <!-- 导航1 -->
    <el-breadcrumb>
      <el-breadcrumb-item>
        <router-link to="/">首页</router-link>
      </el-breadcrumb-item>
      <el-breadcrumb-item>{{ data.title }}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card table" shadow="never">
      <el-form
        :model="data"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item
          label="编辑内容"
          :rules="[{ required: true, message: '请输入内容' }]"
        >
          <div style="position: relative">
            <vue-ueditor-wrap
              v-model="data.content"
              :config="editorConfig"
            ></vue-ueditor-wrap>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" :loading="loading" @click="save('ruleForm')"
            >保存</el-button
          >
          <!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import url from "@/plugins/urlHelper.js";
import VueUeditorWrap from "vue-ueditor-wrap";
export default {
  name: "articleStatic",
  components: { VueUeditorWrap },
  data: () => {
    return {
      loading: false,
      data: {},
      editorConfig: {
        serverUrl: url.getUE(),
        UEDITOR_HOME_URL: url.getUEConfig(),
        initialFrameWidth: "100%",
        initialFrameHeight: 500,
      },
    };
  },
  methods: {
    get() {
      var _this = this;
      var link = url.getSys("get_articleStatic");
      var id = this.$route.params.id;
      $.get(link, { id: id }, (res) => {
        res.content = res.content || "";
        _this.data = res;
      });
    },
    save(formName) {
      var _this = this;

      this.$refs[formName].validate((valid) => {
        if (valid) {
          //提交数据
          var data = _this.data;
          var link = url.getSys("save_articleStatic");
          _this.loading = true;
          $.post(link, _this.data, (res) => {
            _this.loading = false;
            if (res.status) {
              this.$message({
                type: "success",
                message: "保存成功！",
              });
            } else {
              _this.$message({
                type: "error",
                message: res.msg,
              });
            }
          });
        } else {
          return false;
        }
      });
    },
  },
  created() {
    this.get();
  },
  watch: {
    $route() {
      this.get();
    },
  },
};
</script>